.commReportData {
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}
.commReportData th{
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 5px;
    text-align: center;
}
.commReportData td {
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 5px;
    text-align: right;
}

.labelsValue {
    text-align: right;
}

.payoutLegend {
    list-style: none;
    padding: 15px;
}