html, body, main, #root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

a.text-primary {
  color: #007bff;
  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }
}

/*
 Moving truck background class.
 */
.truck-bg {
  background-image: url('./core/media/MovingTruckBackground.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*
  This help with Dropdown and Combobox list showing
  when used in a dialog.
*/
.k-animation-container {
  z-index: 10003
}