table tbody td {
    vertical-align: middle !important;
    // padding: 0 0 0 12px !important;
}

.MuiDialog-paperWidthLg {
    max-width: 850px !important;
}

.shipments-styles .MuiDivider-root {
    background-color: #007bff !important;
    height: 3px !important;
}