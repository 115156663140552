.card-header-extra {
  borderTop: '1px solid rgba(0,0,0,0.125)'
}

.card-body span{
  font-size: 1rem !important;
}

.row-space td{
  padding-bottom: 15px;
}

.shipments-styles .MuiDivider-root {
  background-color: #007bff !important;
  height: 3px !important;
}